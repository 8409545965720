<div
  [class]="class"
  [ngClass]="{
    emailAuth: true,
  }"
>
  @if (!showAuthForm) {
    <app-button
      uid="auth-signin-with-email"
      mixpanelEvent="auth-signin-with-email"
      label="{{
        (isSignupMode() ? 'auth-continue-with-email' : 'auth-login-with-email') | translate
      }}"
      image="assets/img/email.svg"
      color="white"
      text="purple"
      border="purple"
      (click)="toggleShowForm()"
    />
  }
  @if (showAuthForm) {
    <div class="auth-register-credentials">
      <form [formGroup]="form" (ngSubmit)="onSignin()" [@fadeAnimation]>
        <div>
          <div class="forms__formGroup">
            <app-text-input
              uid="registerCredentials-emailInput"
              name="email"
              type="email"
              [label]="'form-label-email' | translate"
              [formControl]="form.controls['email']"
              sensitive
              [required]="true"
            />
          </div>
          <div class="forms__formGroup">
            <app-text-input
              uid="registerCredentials-passwordInput"
              name="password"
              [attr.type]="textInputType"
              [label]="'form-label-password' | translate"
              [formControl]="form.controls['password']"
              [type]="textInputType"
              sensitive
              [required]="true"
            />
            @if (isSignupMode()) {
              <app-password-indicator
                [password]="form.controls['password'].value || ''"
                (click)="createTestAccount()"
              />
            }
            @if (form.controls['password'].value?.length) {
              <i
                class="password-toggle-icon"
                [class.active]="textInputType === 'text'"
                (click)="togglePasswordVisibility()"
              ></i>
            }
          </div>
          <div class="forms__formGroup registerCredentials__submit">
            <button
              uid="register-credentials-signup-button"
              class="button__purple fullwidth"
              [class.loading]="loading"
              [disabled]="!form.valid || loading"
              mixpanelEvent="credentials-page-continue"
              type="submit"
            >
              @if (!accountAlreadyExists && mode !== AuthMode.login) {
                <span>{{ 'auth-signup-or-login' | translate }}</span>
              } @else {
                <span>{{ 'intro-btn-login' | translate }}</span>
              }
              <ng-template #login>
                <span>{{ 'intro-btn-login' | translate }}</span>
              </ng-template>
            </button>
          </div>
        </div>
      </form>
      @if (accountAlreadyExists || mode === AuthMode.login) {
        <a (click)="forgotPassword(form.controls['email']!.value!)">
          {{ 'intro-btn-forgotPassword' | translate }}
        </a>
      }
    </div>
  }
</div>
